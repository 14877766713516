import { Box, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { getCookie } from "../../../Cookies";
import HrloadingScreen from "../../../Hr/LodingHrscreen";
import { IP } from "../../../baseUrlAndConfig";
import SendEmailConfirmationPopUp from "./emailConfirmationPopUp";
// import { functions } from "../../../../Firebase";

const SendEmailPopUp = (props) => {
  const { onClose, open, reportId } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [sendEmailConfirmationPopUp, setSendEmailConfirmationPopUp] =
    useState(false);
  const [arrLength, setArrLength] = useState(0);
  const [tempLoading, setTemploading] = useState("test");
  const [selectedAll, setSelectedAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const [inviteType, setInviteType] = useState("0");
  const [userCompany, setUserCompany] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [extraArr, setExtraArr] = useState([]);
  const [nameAndEmail, setNameAndEmail] = useState("");

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [userData, setUserData] = useState({
    companyId: "",
    courseId: "",
  });
  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState("");
  let navigater = useNavigate();

  const getGroupData = (companyId) => {
    setGroupData([]);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "get",
      params: { companyId: companyId },
      url: `${IP}/company/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setGroupData(res.data.groupData);
    });
  };
  // useEffect(() => {
  //   getGroupData();
  // }, [userData]);
  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        console.log(res.data);
        setUserCompany(res.data);
      }
    });

    // getUserList();
  }, [open]);

  const getUserList = (groupId) => {
    setDetails([]);
    setUserArray([]);
    setArrLength(0);
    let dataex = JSON.stringify({});
    setSelectedAll(false);
    console.log("til");

    let configExInt = {
      method: "get",
      // params: {
      //   company: inviteType == 0 ? companyId : userData.companyId,
      //   courseId: companyId,
      // },
      url: `${IP}/group/users?groupId=${groupId}&company=${userData.companyId}&type="group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      if (res.data.error == "No matches found") {
        setUserArray([]);
        setDetails([]);
        setArrLength(0);

        setLoading(false);
      } else {
        setLoading(false);
        console.log(res.data);
        setUserArray(
          res.data?.users
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setExtraArr(
          res.data?.users
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );

        setDetails(
          res.data?.users
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );

        let tempdata = res.data?.users
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true);
        setArrLength(tempdata.length);

        setCurrentPage(0);

        setLoading(false);
      }
    });
  };
  const getCourseData = (companyId) => {
    setCourseData([]);
    setDetails([]);
    setUserArray([]);
    setArrLength(0);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "post",

      url: `${IP}/company/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setCourseData(res.data.course);
    });
  };

  const runGenerateCertificateTraining = () => {
    if (userData.companyId == "") {
      alert("select company");
      return;
    }
    if (inviteType != 0 && userData.courseId == "") {
      alert("select course");
      return;
    }

    let tempuserdata = details.filter((el) => el.selected == true);
    if (tempuserdata.length == 0) {
      alert("select user");
      return;
    }

    setSendEmailConfirmationPopUp(true);
  };

  const sendEmailFunctionSupport = (data) => {
    // console.log(data);

    let tempuserdata = details.filter((el) => el.selected == true);
    let dataCom = JSON.stringify({
      companyId: userData?.companyId,
      courseId: userData?.courseId,
      emailData: data,
      courseName: courseData?.courseName,
      // courseName:
      //   inviteType == 1 || 2 || 3
      //     ? eval(courseData) &&
      //       courseData.length.filter((el) => el?.courseId == userData?.courseId)
      //         ?.length > 0
      //       ? eval(courseData) &&
      //         courseData?.filter((el) => el?.courseId == userData?.courseId)[0][
      //           "courseName"
      //         ]
      //       : ""
      //     : "",
      companyName: userCompany?.filter(
        (el) => el.companyId == userData.companyId
      )[0].name,
      dueDate: "test",
      // dueDate:
      //   inviteType == 1 || 2 || 3
      //     ? courseData.filter((el) => el.courseId == userData.courseId)
      //         ?.length > 0
      //       ? courseData.filter((el) => el.courseId == userData.courseId)[0]
      //           .dueDate
      //       : "mest"
      //     : "test",

      userArr: tempuserdata,
      type: inviteType,
    });
    console.log({ dataCom });
    let configCom = {
      method: "post",

      url: `${IP}/mail/invitation`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    setLoadingScreen(true);

    axios(configCom)
      .then((res) => {
        console.log(res.data);
        setLoadingScreen(false);
        alert("Success");
        setCourseData(res.data.course);
        // navigater("/admin/course-reports");
        onClose();
        setUserData({
          companyId: "",
          companyId: "",
        });
        setInviteType("0");
      })
      .catch((er) => {
        setLoadingScreen(false);
      });
  };
  const handelCheckBoxChech = (type, ind, item) => {
    if (type == "all") {
      if (!selectedAll) {
        let newARr = details.map((el) => ({
          ...el,
          selected: el.invitationAccept ? false : true,
        }));
        setDetails([]);
        setDetails(newARr);
      } else {
        let newARr = details.map((el) => ({ ...el, selected: false }));
        setDetails([]);
        setDetails(newARr);
      }
      setSelectedAll(!selectedAll);
    } else if (type == "one") {
      if (!details[ind].selected) {
        let newARr = [...details];
        newARr[ind].selected = true;
        ////console.log( newARr[ind].selected)
        setDetails([]);
        // //console.log(newARr)
        setDetails(newARr);
      } else {
        let newARr = [...details];
        newARr[ind].selected = false;
        ////console.log( newARr[ind].selected)
        setDetails([]);
        // //console.log(newARr)
        setDetails(newARr);
      }
    }

    if (type == "sub") {
      let count = 0;
      for (let i = 0; i < details.length; i++) {
        if (details[i].selected && !details[i].invitationAccept) {
          count++;

          let dataex = JSON.stringify({
            name: details[i].name,
            email: details[i].email,
            userId: details[i].userId,
          });
          let configExInt = {
            method: "post",

            url: `${IP}/adminUserProfile/userInvitation`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
            data: dataex,
          };
          axios(configExInt);
        }
      }
      if (count == 0) {
        alert(`select the user invitationAccept pending`);
      } else {
        alert(`invite send to ${count} user`);
      }

      //inviteUserRemainder({name:item.name,email:item.email})
    }
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const filterData = (name) => {
    setNameAndEmail(name);
    console.log(extraArr);
    let newArr = extraArr.filter(
      (el) =>
        el?.name.toLowerCase().includes(name) ||
        el?.email.toLowerCase().includes(name)
    );
    console.log(newArr);
    setDetails(newArr);
  };

  const clearFunction = () => {
    setDetails(extraArr);
    setArrLength(extraArr.length);
    setNameAndEmail("");
    setCurrentPage(0);
  };
  return (
    <Dialog
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minHeight: "40vh",
          maxHeight: "90vh",
          minWidth: "70vw",
          padding: "16px",
          overflow: "scroll",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <HrloadingScreen
        open={loadingScreen}
        onClose={() => {
          setLoadingScreen(false);
        }}
      />

      <SendEmailConfirmationPopUp
        open={sendEmailConfirmationPopUp}
        onClose={() => {
          setSendEmailConfirmationPopUp(false);
        }}
        sendEmailFun={(data) => {
          sendEmailFunctionSupport(data);
        }}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Invite </p>
        <button
          onClick={() => {
            handleClose();
            if (loading) return;
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div>
            {" "}
            <Select
              value={inviteType}
              onChange={(e) => {
                setInviteType(e.target.value);
                setUserData({
                  companyId: "",
                  companyId: "",
                });
              }}
              sx={{
                width: "300px",
                height: "30px",
              }}
            >
              <MenuItem value="0"> DEI SaaS Tool invite - HR</MenuItem>

              <MenuItem value="1"> Course invite</MenuItem>
              <MenuItem value="2"> Needstreet Course invite </MenuItem>
              <MenuItem value="3"> Coc Course Invite </MenuItem>
              <MenuItem value="4"> Gainsight Course Invite </MenuItem>
              <MenuItem value="5"> UC Values and Policies Invite </MenuItem>
              <MenuItem value="6"> UC Handbook for Senior </MenuItem>
            </Select>{" "}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {" "}
            <Box> Select company</Box>
            <Select
              value={userData.companyId}
              onChange={(e) => {
                // getCourseData(e.target.value);
                getGroupData(e.target.value);
                console.log(inviteType);
                console.log(e.target.value);
                if (
                  inviteType == "0" ||
                  inviteType == "1" ||
                  inviteType == "2" ||
                  inviteType == "3" ||
                  inviteType == "4" ||
                  inviteType == "5" ||
                  inviteType == "6"
                ) {
                  console.log("why not here");
                  getUserList(e.target.value);
                }
                setUserData((prev) => ({
                  ...prev,
                  companyId: e.target.value,
                }));
              }}
              // onChange={(e) => {
              //   getCourseData(e.target.value);
              //   if (inviteType == "0") {
              //     getUserList(e.target.value);
              //   }
              //   setUserData((prev) => ({
              //     ...prev,
              //     companyId: e.target.value,
              //   }));
              // }}
              sx={{
                width: "300px",
                height: "30px",
                mt: "10px",
              }}
            >
              <MenuItem> select</MenuItem>
              {userCompany?.map((el) => (
                <MenuItem value={el.companyId}> {el.name}</MenuItem>
              ))}
            </Select>{" "}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {" "}
            <Box> Select group</Box>
            <Select
              value={groupId}
              onChange={(e) => {
                let data = [];
                setCourseData(
                  groupData.filter((el) => el.groupId == e.target.value)[0]
                    ?.course
                );
                setGroupId(e.target.value);
                getUserList(e.target.value);
              }}
              sx={{
                width: "300px",
                height: "30px",
                mt: "10px",
              }}
            >
              {groupData?.map((el) => (
                <MenuItem value={el?.groupId}> {el?.groupName}</MenuItem>
              ))}
            </Select>{" "}
          </div>
        </div>

        {inviteType == "1" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select course</Box>
                <Select
                  value={userData.courseId}
                  onChange={(e) => {
                    // getCourseData(e.target.value);
                    setUserData((prev) => ({
                      ...prev,
                      courseId: e.target.value,
                    }));
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select</MenuItem>

                  {courseData?.map((el) => (
                    <MenuItem value={el.courseId}> {el.courseName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>
          </>
        )}
        {(inviteType == "2" ||
          inviteType == "3" ||
          inviteType == "4" ||
          inviteType == "5" ||
          inviteType == "6") && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select course</Box>
                <Select
                  value={userData.courseId}
                  onChange={(e) => {
                    // getUserList(e.target.value);
                    setUserData((prev) => ({
                      ...prev,
                      courseId: e.target.value,
                    }));
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select</MenuItem>

                  {courseData?.map((el) => (
                    <MenuItem value={el.courseId}> {el.courseName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>
          </>
        )}

        <div>
          <Button
            variant="contained"
            sx={{
              mt: "2em",
              color: "#fff",
              width: "200px",
              height: "40px",
              background: "#11047A",
              borderRadius: "70px",
            }}
            disable={loading}
            onClick={runGenerateCertificateTraining}
          >
            {loading ? "Sending..." : "Send email"}
          </Button>
        </div>

        <div style={{ display: "flex" }}>
          {" "}
          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">
              {t("Name & Email")}
            </div>
            <div className="admin-overdue-mid-left-input">
              <input
                style={{
                  width: "250px",
                  height: "40px",
                  fontSize: "20px",
                }}
                value={nameAndEmail}
                onChange={(e) => filterData(e.target.value)}
              />
            </div>
          </div>
          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("")}</div>
            <div className="admin-overdue-mid-left-input">
              <Button
                variant="contained"
                sx={{
                  mt: "3vh",
                  color: "#fff",
                  width: "200px",
                  height: "40px",
                  background: "#11047A",
                  borderRadius: "70px",
                }}
                // disable={loading}
                onClick={clearFunction}
              >
                {"Clear"}
              </Button>
            </div>
          </div>
        </div>
        <div className="admin-overdue-bottom">
          <div className="admin-overdue-bottom-table">
            <div
              className="admin-overdue-bottom-table-cnt-usr"
              style={{
                gridTemplateColumns: "0.5vw 1fr 1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div
                className="admin-overdue-bottom-table-head"
                style={{
                  marginLeft: "-8px",
                }}
              >
                <input
                  style={{
                    height: "1vw",
                    width: "1vw",
                    position: "relative",
                    top: "5%",
                    left: "-10%",
                  }}
                  type="checkbox"
                  checked={selectedAll}
                  //  value={selectedAll}
                  onClick={(e) => {
                    handelCheckBoxChech("all", "no", e.target.checked);
                  }}
                />
              </div>
              <div className="admin-overdue-bottom-table-head">{t("")}</div>
              <div className="admin-overdue-bottom-table-head">{t("Name")}</div>
              <div className="admin-overdue-bottom-table-head">
                {t("Email")}
              </div>

              <div className="admin-overdue-bottom-table-head">
                {t("JobTitle")}
              </div>

              <div className="admin-overdue-bottom-table-head">
                {t("InviteAccept")}
              </div>
              <div className="admin-overdue-bottom-table-head">{t("Role")}</div>
            </div>

            {details.length == 0 ? (
              <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td">
                  <div className="spinner-container">
                    <div> No User found</div>
                  </div>
                </div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div
                  className="admin-overdue-bottom-table-td"
                  style={{ color: "#004577" }}
                ></div>
              </div>
            ) : null}
            {details ? (
              details.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;

                return (
                  <div
                    className="admin-overdue-bottom-table-cnt-usr"
                    key={id}
                    style={{
                      gridTemplateColumns: "1vw 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      <input
                        style={{
                          height: "1vw",
                          width: "1vw",
                          position: "relative",
                          top: "5%",
                          left: "-10%",
                        }}
                        type="checkbox"
                        checked={item.selected}
                        onChange={(e) => {
                          handelCheckBoxChech("one", id, e.target.checked);
                        }}
                      />
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {" "}
                      {/* {item?.employeeId} */}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.name}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {item?.email}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {item?.jobTitle}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {item?.invitationAccept ? "Accepted" : "Pending"}
                    </div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#FD5353" }}
                    >
                      {item.role}
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No user </p>
            )}
          </div>
          <div className="admin-overdue-bottom-pagination">
            <div className="admin-overdue-bottom-pagination-cnt">
              <div className="admin-overdue-bottom-pagination-cnt-item">
                <svg
                  onClick={() => clickhandler(currentPage - 1)}
                  width="auto"
                  height="22"
                  viewBox="0 0 14 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
              </div>
              <div
                style={{ marginRight: "19vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <svg
                  onClick={() => clickhandler(currentPage + 1)}
                  width="auto"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div
                style={{ display: "flex", marginRight: "-23vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <label className="admin-row">{t("Show")}</label>
                <select
                  style={{
                    width: "4vw",
                    margin: "0.5vw",
                    marginBottom: "1.5vw",
                    height: "1.5vw",
                  }}
                  value={pageSize}
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <label className="admin-row"> {t("PagePerItem")}</label>
              </div>
            </div>
          </div>
          <div>
            {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
            {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default SendEmailPopUp;
