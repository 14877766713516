import Rectangle68 from "./Mask group.png";

const plans = [
  {
    name: "DEI goals setting with Serein",
    s: true,
    p: true,
    e: true,
  },
  {
    name: "Gender inclusion metric",
    s: true,
    p: true,
    e: true,
  },

  {
    name: "DEI strategy, toolkits and playbooks",
    s: true,
    p: true,
    e: true,
  },

  {
    name: "Expert talks",
    s: true,
    p: true,
    e: true,
  },

  {
    name: "Virtual masterclass",
    s: true,
    p: true,
    e: true,
  },

  {
    name: "Admin tracking dashboard",
    s: true,
    p: true,
    e: true,
  },

  {
    name: "Schedule timely reminders",
    s: false,
    p: true,
    e: true,
  },

  {
    name: "Deep-drive course",
    s: false,
    p: true,
    e: true,
  },

  {
    name: "Inclusion calendar",
    s: false,
    p: true,
    e: true,
  },
  {
    name: "Timely employee engagement creative",
    s: false,
    p: true,
    e: true,
  },
  {
    name: "Inclusion studies",
    s: false,
    p: true,
    e: true,
  },

  {
    name: "Leadership workshop",
    s: false,
    p: true,
    e: true,
  },

  {
    name: "Microlearning",
    s: false,
    p: false,
    e: true,
  },

  {
    name: "Inclusion game",
    s: false,
    p: false,
    e: true,
  },

  {
    name: "Diversity index (PWD, LGBTQ, Race, Religion and Region)",
    s: false,
    p: false,
    e: true,
  },

  {
    name: "Trust building exercise for data collection",
    s: false,
    p: false,
    e: true,
  },

  {
    name: "Exclusive manager workshop",
    s: false,
    p: false,
    e: true,
  },

  {
    name: "Exclusive employee workshop",
    s: false,
    p: false,
    e: true,
  },
];

let microArr = [
  {
    name: "How to use Serein’s culture equation?",
    img: Rectangle68,
  },
  {
    name: "How to use the learning dashboard?",
    img: Rectangle68,
  },

  {
    name: "How to use the progress dashboard?",
    img: Rectangle68,
  },

  {
    name: "How to use the inclusion calendar ? ",
    img: Rectangle68,
  },
];

export { plans, microArr };
