//server
//const IP = "http://139.59.65.117:4000";
//local
const dev = process.env.NODE_ENV;
console.log({ dev });
// const IP = dev ? "http://172.16.0.26:3000" : "https://api.sereininc.live";
// const IP = "http://localhost:3000";
// const IP = "http://172.16.0.20:3000";
const IP = "https://sereindevapi.kdev.co.in";

// const IP = "https://api.sereininc.com";
// const IP = "https://api.sereininc.com";
export { IP };
