//import * as functions from 'firebase-functions';
import React, { useState, useEffect, useRef } from "react";
import "../style/User.css";

// import styledComponents from "styled-components";
// import { collection, addDoc } from "firebase/firestore";
// import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
// import {
//   isSignInWithEmailLink,
//   createUserWithEmailAndPassword,
//   signInWithEmailLink,
// } from "firebase/auth";
// import { sendPasswordResetEmail } from "firebase/auth";
// import $ from "jquery";

import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../Cookies";
import { t } from "i18next";
import Sidebar from "../Component/Sidebar";
import Navbar from "../Component/NavbarTop";
import { useSelector } from "react-redux";

import { auth, db, functions } from "../../Firebase";
import { Link } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { display } from "@mui/system";
import { ValidateAuth } from "../../AuthToken";

import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
const AddNewUser = ({ handleLogout }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const spanStyle = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "-0.2vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const spanStyleINS = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "0.4vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const location = useLocation();
  const editUserState = location.state;
  ////console.log(editUserState);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [isUser, setIsUser] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [isHR, setIsHR] = useState(false);
  const [isICC, setICC] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  //const [disabledButt,setDisabledButt]=useState(false)
  let disabledButt = false;

  if (editUserState) {
    //setDisabledButt(true)
    disabledButt = true;
  }

  ////console.log(location.state);
  const handleMouseOver = (data) => {
    if (data == "User") {
      setIsUser(true);
    } else if (data == "Admin") {
      setIsAdmin(true);
    } else if (data == "HR") {
      setIsHR(true);
    } else if (data == "ICC") {
      setICC(true);
    }
  };

  const handleMouseOut = (data) => {
    if (data == "User") {
      setIsUser(false);
    } else if (data == "Admin") {
      setIsAdmin(false);
    } else if (data == "HR") {
      setIsHR(false);
    } else if (data == "ICC") {
      setICC(false);
    }
  };
  // useEffect(() => {}, []);
  const goToUser = () => navigate("/admin/users");

  const [fullname, setFullName] = useState("");
  const [company, setCompany] = useState("default");
  const [companyDetails, setCompanyDetails] = useState(null);
  const [department, setDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState([]);

  const [email, setEmail] = useState([]);
  const [group, setGroup] = useState([]);

  const [groupData, setGroupData] = useState([]);

  const [role, setRole] = useState([]);
  const [error, setError] = useState();
  const [prevgroup, setPrevgroup] = useState({});

  const inputRef = useRef(null);

  useEffect(() => {
    if (editUserState) {
      console.log(editUserState);
      setFullName(editUserState.name);
      setCompany(editUserState.companyId);

      setJobTitle(editUserState.jobTitle);

      setEmail(editUserState.email);
      setGroup(editUserState.groupId);

      setRole(editUserState.role);
    }
  }, [editUserState]);

  const allIsWell = () => {
    setError("");
    console.log(group.length);
    if (fullname == "") {
      setError("Full Name");
      return false;
    } else if (company == "default") {
      setError("Company");
      return false;
    } else if (jobTitle == "") {
      setError("Job Title");
      return false;
    } else if (group.length == 0) {
      setError("Group ");
      return false;
    } else if (email == "") {
      setError("Email");
      return false;
    } else if (role == "") {
      setError("Role");
      return false;
    }

    return true;
  };

  const handleCreateUser = () => {
    // buttendesiblefunction()
    console.log("i am here");
    if (!allIsWell()) return;

    // setButtonDisable(false);
    if (editUserState) {
      // //console.log({
      //   uid: editUserState.userId,
      //   name: fullname,
      //   company: company,
      //   role: role,
      //   instructorSignature: signData.base64,
      //   jobTitle: jobTitle,
      //   department: department,
      // });

      let dataex = JSON.stringify({
        uid: editUserState.userId,
        name: fullname,
        companyId: company,
        role: role,
        departmentId: department,
        jobTitle: jobTitle,
        groups: group,
        email,
      });

      let configExInt = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/adminUserProfile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };

      axios(configExInt)
        .then((res) => {
          // //console.log(res);
          setButtonDisable(true);
          if (res.data.message == "Sucess") {
            alert("user updation successfully");
            goToUser();
          } else {
            setError("User Updation Failed!!");
          }
        })
        .catch((err) => {
          ////console.log(err);
          setButtonDisable(true);
        });
    } else {
      let dataex = JSON.stringify({
        name: fullname,
        companyId: company,
        departmentId: department,
        email: email.trim(),
        role: role,
        groups: group,
        jobTitle: jobTitle,
      });
      // return console.log({
      //   name: fullname,
      //   companyId: company,
      //   departmentId: department,
      //   email: email.trim(),
      //   role: role,
      //   groups: group,
      //   jobTitle: jobTitle,
      // })
      let configExInt = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/adminUserProfile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          // //console.log(res);
          setButtonDisable(true);
          alert("user created successfully");
          goToUser();
        })
        .catch((err) => {
          console.log(err);
          setError("Check all details properly");
          setButtonDisable(true);
        });
    }
  };

  // department and company get
  useEffect(() => {
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      if (res.data.error) {
        return;
      }
      setDepartmentList(res.data);
    });
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (res.data.error) {
        return;
      }
      setCompanyDetails(res.data);
    });
    // db.collection("company")
    //   .get()
    //   .then((snapshot) => {
    //     var comp = [];
    //     snapshot.forEach((company) => {
    //       let temp = {
    //         name: company.data().name,
    //         id: company.id,
    //       };
    //       comp.push(temp);
    //     });
    //     setCompanyDetails(comp);
    //   });
  }, []);

  // get company
  useEffect(() => {
    if (!company) return;
    let configExInt = {
      method: "get",
      url: `${IP}/company/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      params: {
        companyId: company,
      },
    };

    axios(configExInt).then((res) => {
      if (res.data?.error) {
        return;
      }
      setGroupData(res.data?.groupData);
    });
  }, [company]);

  const handleGroupCheck = (id) => {
    if (group.includes(id)) {
      const filter = group.filter((i) => i !== id);
      setGroup(filter);
    } else {
      setGroup((prev) => [...prev, id]);
    }
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("User")} />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />

        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("AddNewUser/EditUser")}
            </div>
          </div>
          <div
            className="admin-course-main-top"
            style={{ margin: "2vw", padding: "1vw 2vw 4vw 2vw" }}
          >
            <div>
              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("FullName")} *</p>
                <input
                  type="text"
                  id="name"
                  className="admin-course-main-top-input"
                  value={fullname}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                />
                {/* <span style={{color:"red", display:"inline"}} > {error && <span>{error} Not Filled</span>}</span> */}
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Company")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="company"
                  value={company}
                  onChange={(event) => {
                    const c = event.target.value;
                    console.log(prevgroup[c]);
                    if (prevgroup[c]) {
                      setGroup(prevgroup[c]);
                    } else {
                      setPrevgroup((prev) => ({
                        ...prev,
                        [company]: group,
                      }));
                      setGroup([]);
                    }
                    setCompany(c);
                  }}
                >
                  <option value={"default"}>Select Company</option>
                  {companyDetails?.map((value, index) => (
                    <option key={index} value={value.companyId}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Group")} *</p>
                <div className="gridGroupCheckbox">
                  {groupData &&
                    (groupData || [])?.map((g, idx) => {
                      return (
                        <div className="gridGroupCheckboxItem" key={g?.groupId}>
                          <input
                            type="checkbox"
                            onChange={() => handleGroupCheck(g?.groupId)}
                            checked={group.includes(g?.groupId)}
                            id={`group-checkbox-${idx}`}
                          />
                          <label
                            className="inputLabel1"
                            htmlFor={`group-checkbox-${idx}`}
                            value={g?.groupId}
                          >
                            {g?.groupName}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Department")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="department"
                  value={department}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                >
                  <option value={"default"}>Select Department</option>
                  {departmentList?.map((value, index) => (
                    <option key={index} value={value.companyId}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div> */}

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("JobTitle")} *</p>
                <input
                  type="text"
                  id="jobtitle"
                  className="admin-course-main-top-input"
                  value={jobTitle}
                  onChange={(event) => {
                    setJobTitle(event.target.value);
                    // setJobTitle(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("Email")} *</p>
                <input
                  type="text"
                  id="email"
                  value={email}
                  className="admin-course-main-top-input"
                  // disabled={disabledButt}
                  onChange={(event) => {
                    // location.state.email?setEmail(location.state.email):
                    setEmail(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <div
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  className="label"
                >
                  {t("Role")} *
                </div>
                <div style={{ fontSize: "0.9vw" }}>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="ADMIN"
                      name="role"
                      checked={role == "ADMIN" ? true : false}
                    />
                    {t("Admin")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("Admin");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("Admin");
                      }}
                      style={{
                        background: isAdmin ? "black" : "white",
                        marginLeft: "3vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isAdmin && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS} className="spanData">
                          {t("AdminIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="USER"
                      name="role"
                      checked={role == "USER" ? true : false}
                    />{" "}
                    {t("User")}{" "}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("User");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("User");
                      }}
                      style={{
                        background: isUser ? "black" : "white",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isUser && (
                      <>
                        <span style={{ fontSize: "20px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyle} className="spanData">
                          {t("UserIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="HR"
                      name="role"
                      checked={role == "HR" ? true : false}
                    />{" "}
                    {t("HR")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("HR");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("HR");
                      }}
                      style={{
                        background: isHR ? "black" : "white",
                        marginLeft: "2vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isHR && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS}>{t("HrIns")}</span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="ICC"
                      name="role"
                      checked={role == "ICC" ? true : false}
                    />{" "}
                    {t("ICC")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("ICC");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("ICC");
                      }}
                      style={{
                        background: isICC ? "black" : "white",
                        marginLeft: "2vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isICC && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS}>{t("IccIns")}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {error && <div style={{ color: "red" }}>{error} Not Filled</div>}
              <div
                style={{ justifyContent: "center" }}
                className="admin-dashboard-main-mid-item-last-filter-notify"
              >
                <div className="admin-dashboard-main-top-last-filter-notify">
                  {buttonDisable ? (
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      onClick={handleCreateUser}
                    >
                      <div>{t("Save")} </div>
                    </button>
                  ) : (
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      disabled={true}
                    >
                      <div>{t("Loading")} </div>
                    </button>
                  )}

                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <div>{t("Cancel")}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              onClick={() => navigate(-1)}
            >
              {t("Back")}
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
