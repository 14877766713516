import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

const CommonModal = ({ open, close }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Too Many Requests
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have requested too many times.Please try after some time.
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              sx={{
                // display: "flex",
                // justifyContent: "end",
                mt: "10px",
              }}
              variant="contained"
              onClick={close}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CommonModal;
