import { Box } from "@mui/system";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import img from "../devlopment.png";
import { getCookie } from "../../Cookies";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MoreEventPage from "./MoreOption";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import "./event.css";
import { IP } from "../../baseUrlAndConfig";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import HrloadingScreen from "../LodingHrscreen";
import MobileTopBar from "../Sidebar/MobaileTopBar";
const DEI = () => {
  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);

  const [yeardata, setYearData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [eventList, setEventList] = useState([]);
  const [popdata, setpopdata] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);

  useEffect(() => {
    // console.log(newobj.HRDI);
    // if (!newobj) {
    //   navigate(-1);
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      setLoadingScreen(true);
    } else {
      setLoadingScreen(false);

      let newobj =
        rolesAndPermission?.Calender?.componentPermission.includes(
          "DEI Calender"
        );
      // console.log(newobj.HRDI);
      if (!newobj) {
        navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);

  const selectYear = (year) => {
    let configrecrutci = {
      method: "get",

      url: `${IP}/subscription/year`,
      params: { year: year ? year : "" },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        console.log(res.data);
        const d = new Date();
        const currentYear = d.getFullYear();
        console.log(currentYear);
        setYearData([...res.data, currentYear]);

        // setUserCourses([...res.data]);
        // const temp = res.data.find((course) => course.courseId == courseId);
        // console.log(temp);
        // setUserCourses(temp);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getevent = (year) => {
    let configrecrutci = {
      method: "get",

      url: `${IP}/event/shortedEvents`,
      params: { year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        console.log(res.data);
        setEventList(res.data);
        // setUserCourses([...res.data]);
        // const temp = res.data.find((course) => course.courseId == courseId);
        // console.log(temp);
        // setUserCourses(temp);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getevent();
    selectYear("");
  }, []);

  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = (e) => {
      console.log(e);
      if (e?.target?.innerWidth) {
        setScreenWidth(e?.target?.innerWidth);
      }
    };
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="hrmainbox"
      style={{
        background: "#F3F1FF",
        minHeight: "100vh",
      }}
    >
      <ResponsiveDrawer sidebar={"DEI"} />

      <HrloadingScreen open={loadingScreen} />

      <Box sx={{ width: "98%" }}>
        <MobileTopBar sidebar={"DEI-Calendar"} />

        <HrTopNavbar title={`DEI calendar`}>
          <Box
            sx={{
              width: "140px",
              height: "35px",
            }}
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label68">Year</InputLabel>
              <Select
                onChange={(e) => {
                  getevent(e.target.value);
                  setSelectedYear(e.target.value);
                }}
                value={selectedYear}
                label="Year"
                labelId="demo-simple-select-label68"
                id="demo-simple-select-label68"
                sx={{
                  color: "#6846C7",
                  textAlign: "center",
                  width: "140px",
                  padding: "2px",
                  height: "45px",
                }}
              >
                {yeardata.map((el) => (
                  <MenuItem value={el}> {el}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </HrTopNavbar>

        <MoreEventPage
          open={openpopUpForAddYears}
          onClose={() => setOpenpopUpForAddYears(false)}
          // delfun={(topic) => {
          //   addYearsFunPopUp(topic);
          // }}
          data={popdata}
        />

        <div className="DEICourseGridMap">
          {eventList?.map((el) => {
            let items = [];
            if (screenWidth >= 600) {
              if (el?.item && el?.item?.length > 2) {
                items = el?.item.slice(0, 2);
              } else if (el?.item && el?.item?.length <= 2) {
                items = el?.item;
              }
            } else {
              items = el?.item || [];
            }

            return (
              <Box className="DEICalendarMonthItem">
                <Box
                  id="month"
                  sx={{
                    color: "#666666",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    // paddingLeft: "8px",
                  }}
                >
                  {el.month}
                </Box>
                <Box
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  {items?.map((ele, i) => (
                    <Box
                      onClick={() => {
                        navigate("/hr/DEI_view", {
                          state: {
                            ...ele,
                          },
                        });
                      }}
                      sx={{
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "flex",
                        alignItems: "center",
                        minHeight: "32px",
                        borderRadius: "8px",
                        padding: "5px 8px",

                        marginTop: "8px",
                        backgroundColor:
                          ele.basedon == "day"
                            ? "rgba(254, 156, 191, 0.2)"
                            : "rgba(225, 245, 102, 0.2)",
                      }}
                    >
                      {ele.name}
                    </Box>
                  ))}
                </Box>

                {screenWidth >= 600 && el.item?.length > 2 && (
                  <Box
                    sx={{
                      color: "#6846C7",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      cursor: "pointer",
                      mt: "8px",
                      paddingLeft: "8px",
                    }}
                    onClick={() => {
                      setpopdata(el);
                      setOpenpopUpForAddYears(true);
                    }}
                  >
                    {el.item?.length - 2} More
                  </Box>
                )}
              </Box>
            );
          })}
        </div>
      </Box>
    </div>
  );
};
export default DEI;
