import React, { useEffect, useState } from "react";
import Sidebar from "../../../Component/Sidebar";
import { t } from "i18next";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavbarTop from "../../../Component/NavbarTop";

import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BackupDownload = () => {
  //================company settings===========================//
  const [template, settemplate] = useState(null);
  const [allBackups, setAllBackups] = useState([]);
  const [latestDate, setLatestDate] = useState("");
  const [loading, setLoading] = useState(false);
  // delete company
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/backup`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        const tempArr = [];
        res.data.map((val, idx) => {
          if (val.includes("2023")) {
            console.log("s");
            tempArr.push(val);
            setAllBackups(...allBackups, val);
          }
        });
        console.log("asdas");
        // setAllBackups(res.data);
        // tempArr.sort();
        console.log(tempArr[tempArr.length - 1]);
        setLatestDate(tempArr[tempArr.length - 1]);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const getData = () => {};

  useEffect(() => {
    fetchdata();
  }, []);

  const downloadIt = async () => {
    console.log("downloading");
    // const response = await fetch(`${IP}/backup/${latestDate}`);
    // const movies = await response.json();
    // console.log(movies);
    var config = {
      method: "get",

      url: `${IP}/backup/${latestDate}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        console.log(typeof res.data);

        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <section className="dashboard">
      <NavbarTop page={t("Company Report")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div className="outer-bigger-container">
            {/* Company settings */}

            <Button
              variant="contained"
              style={{
                marginBottom: "20px",
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>

            <h1>Download Backup</h1>
            <div
              className="create-new-box"
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
              }}
            >
              <a
                href={`${IP}/backup/${latestDate}`}
                download="Example-PDF-document"
                target="_blank"
                rel="noreferrer"
              >
                <button>Download Backup</button>
              </a>
              {/* <Button
                variant="contained"
                component="span"
                onClick={() => downloadIt()}
                sx={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                Download Here
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BackupDownload;
