import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createRef,
  useCallback,
} from "react";
import ReactPlayer from "react-player/lazy";
import screenfull from "screenfull";
import { ReactComponent as Play } from "./icons/play.svg";
import { findDOMNode } from "react-dom";
import { ReactComponent as Pause } from "./icons/pause.svg";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";

import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import { CheckBox } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";
const VideoPlayer = ({ videoURL, itemId, setCanNavigate }) => {
  // const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [duration, setDuration] = useState(0);
  const { courseId, isLinear, sample } = useContext(ItemContext);
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const setVideoProgress = httpsCallable(functions, "setVideoProgress");
  const [progressFetched, setProgressFetched] = useState(false);
  const [progress, setProgress] = useState();
  const [percent, setPercent] = useState(0);
  const [screenSize, setScreenSize] = useState("50vw");
  const [flag, setFlag] = useState(false);
  const playerRef = useRef();
  const progressRef = createRef();
  progressRef.current = progress;

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleProgress = (event) => {
    // console.log(event.playedSeconds);
    if (event.playedSeconds > 0) setProgress(event.playedSeconds);
    let currentProgress = Math.floor((event.playedSeconds / duration) * 100);
    // console.log(currentProgress, duration);
    setPercent(currentProgress);
  };

  const handleOnReady = () => {
    //console.log("Time start", progress);
    playerRef.current.seekTo(progress, "seconds");
  };

  const resetProgress = (secs) => {
    playerRef?.current.seekTo(secs);
    // setProgress(Math.floor((secs / duration) * 100));
  };

  // useEffect(() => {}, [videoURL]);
  useEffect(() => {
    // console.log(videoURL);
    const COMPLETE_PERCENT = 100;
    if (percent > 0 && percent % 10 == 0 && !sample) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userCourseContent/video`,
        // params: { courseId: courseId, itemId: itemId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
          lastDuration: progress,
          completed: Boolean(percent >= COMPLETE_PERCENT),
        }),
      };

      if (percent >= COMPLETE_PERCENT) {
        axios(configrecrut)
          .then((res) => {
            setCompleted(true);
          })
          .catch((err) => {
            toast.error("Something went wrong, please retry");

            // loading screen with retry for user action
          });
      } else {
        axios(configrecrut);
      }
    }
  }, [percent]);

  useEffect(() => {
    console.log("HEYYYYYY I am CHANGED...........");
    // const handleTabChange = (event) => {
    //   setFlag(!flag);
    //   const player = playerRef.current;
    //   // console.log({ player });

    //   if (document.visibilityState == "visible") {
    //     // console.log(isPlaying);
    //     // player.isPlaying(false);

    //     setIsPlaying((prev) => true);
    //   } else {
    //     // player.handlePause();
    //     // player.isPlaying(false);

    //     setIsPlaying((prev) => false);
    //   }
    // };

    // document.addEventListener("visibilitychange", handleTabChange);

    let configrecrut = {
      method: "get",

      url: `${IP}/userProgress/items`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut)
      .then((result) => {
        if (result.data.error === "not present") {
          setCompleted(false);
        } else if (result.data.completed === true) {
          setCompleted(true);
        }
        if (result.data.lastDuration != undefined) {
          //console.log(result.data.lastDuration);
          setProgress(result.data.lastDuration);

          //console.log("seek video");
        }
        setProgressFetched(true);
      })
      .catch((er) => {
        // console.log("dfdsg");
      });

    // very important to remove event listner when the component unmounts
    // return () => {
    //   document.removeEventListener("visibilitychange", handleTabChange);
    // };
  }, []);

  useEffect(() => {
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  const fullscreen = () => {
    screenfull.request(findDOMNode(playerRef.current));

    // document.getElementById("testdomel").style.height = "1000px";
    // ds.style.width = "800px";
    // playerRef.current
    // screenfull.request(

    //   document.getElementById("testdomel")
    // );
  };

  useEffect(() => {
    // console.log(window.screen.width);

    window.addEventListener("resize", () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 720) {
        setIsPlaying(false);

        setScreenSize("95vw");
      } else {
        setIsPlaying(false);

        setScreenSize("50vw");
      }
      return window.innerWidth;
    });
    if (window.innerWidth < 720) {
      // console.log("withe", window.innerWidth);
      setIsPlaying(false);

      setScreenSize("95vw");
    }
    return window.removeEventListener("resize", () => {});
  }, []);

  return (
    <div className="videoPlayer-container">
      {progressFetched && (
        <Box id="testdomel">
          <ReactPlayer
            ref={playerRef}
            url={videoURL}
            playing={isPlaying}
            controls={true}
            onPlay={handlePlay}
            width={screenSize}
            // height={"70vh"}
            onPause={handlePause}
            onProgress={handleProgress}
            onDuration={(num) => setDuration(num)}
          />
        </Box>
      )}

      {/* <div
        className="videoPlayer-controls"
        style={{
          backgroundColor: "#DACCFF",
          height: "54px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            backgroundColor: "#E1F566",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isPlaying ? (
            <Pause
              onClick={handlePlayPause}
              className="videoPlayer-playPause"
            />
          ) : (
            <Play onClick={handlePlayPause} className="videoPlayer-playPause" />
          )}
        </div>

        <div className="markCompleted">
          <Button
            sx={{
              width: "120px",
              hight: "40px",
              // border: "1px solid #F3F1FF",
              backgroundColor: "#E1F566",
              outline: "none",
            }}
            onClick={() => {
              fullscreen();
            }}
          >
            {" "}
            Full Screen{" "}
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default VideoPlayer;
