import { Box, styled } from "@mui/system";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import { getCookie, deleteCookie, deleteCookiesInServer } from "../../Cookies";
import "./hrprofile.css";
import { RightArrowIconhr } from "../icons/Icons";
import { HrIcorTick } from "./hrtickicone";
import userimg from "./user.png";

import { Grid, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IP } from "../../baseUrlAndConfig";
import { plans, microArr } from "./plansdata";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import Changepassword from "./chnagepassword";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import MobileTopBar from "../Sidebar/MobaileTopBar";

const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));
const HrProfilePage = () => {
  const navigate = useNavigate();
  const [todayDate, setTodayDate] = useState("");
  const [expDate, setExpDate] = useState("");
  const [subExpired, setSubExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passchengestate, setPasschengestate] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    plan: "",
    startDate: "",
    endDate: "",
    subscription: "",
  });
  const location = useLocation();
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  const checkExpiry = () => {
    console.log("am i checkning?");
    for (let [key, value] of Object.entries(rolesAndPermission)) {
      if (key == "Profile" || key == "Strategy") {
        continue;
      } else {
        if (value.viewPermission == true) {
          console.log("i am returning false");
          return false;
        }
      }
    }
    console.log("i am returning true");
    return true;
  };
  useEffect(() => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      setUserData(res.data.message);
      if (checkExpiry()) {
        setSubExpired(true);
      }
      setLoading(false);
    });
  }, []);

  //  console.log(userData);
  return (
    <div
      className="hrmainbox"
      style={{ background: "#F3F1FF", minHeight: "100vh" }}
    >
      <ResponsiveDrawer sidebar={"Profile"} />

      <Changepassword
        open={passchengestate}
        onClose={() => {
          setPasschengestate(false);
        }}
      />

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 20,
            backgroundColor: "#F3F1FF",
          }}
        >
          <MobileTopBar sidebar={"Profile"} />
          <HrTopNavbar title={`Profile`} />
        </Box>
        <Box className="HrProfileMainSec">
          <Box className="HrProfileMainSecGrid1">
            <Box
              sx={{
                // border: "1px solid red",
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                }}
              >
                <img src={userimg} alt="" />{" "}
              </Box>
            </Box>
            <Box
              sx={{
                // border: "1px solid red",
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Box className="hrpageheding"> {userData?.name}</Box>
              <Box className="hrbodytext">
                <Box sx={{ wordBreak: "break-all" }}> {userData?.email}</Box>
                <Box className="HrProfileNameRoleDivider"> </Box>
                <Box>{userData?.plan}</Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="logouthr"
            sx={{
              // border: "1px solid red",
              background: "#6846C7",

              borderRadius: "24px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                cursor: "pointer",
                padding: "20px 35px 20px 35px",
              }}
              onClick={() => {
                setPasschengestate(true);
              }}
            >
              <Box> Change password</Box>
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPasschengestate(true);
                }}
              >
                <RightArrowIconhr />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                paddingLeft: "30px",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #5E3FB3",
                  width: "65%",
                }}
              >
                {" "}
              </Box>{" "}
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                cursor: "pointer",
                padding: "20px 35px 20px 35px",
              }}
              onClick={() => {
                navigate("/");

                deleteCookie();
                // deleteCookiesInServer();
                // window.location.reload();
                // window.location.reload();
              }}
            >
              <Box> Logout</Box>
              <Box>
                {" "}
                <RightArrowIconhr />
              </Box>
            </Box>
          </Box>
        </Box>
        {subExpired && !loading && localStorage.getItem("ROLE") === "USER" && (
          <Box
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: "20px",
            }}
          >
            *Contact your admin*
          </Box>
        )}

        {rolesAndPermission?.Profile?.componentPermission.includes("Table") && (
          <Box className="HrProfileTableContainer">
            <Box className="HrProfileTableWithHeading">
              <Box className="HrProfileTableHeading">
                <Box className="hrpageheding mobSub">
                  {userData?.subscription} subscription
                </Box>

                <Box className="HrProfileTableHeadingGrid2">
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateRows: "1fr 1fr",
                    }}
                  >
                    {" "}
                    <Box className="hrbodytext startText"> Start date</Box>
                    <Box className="hrpageheding startDate">
                      {" "}
                      {userData?.startDate}{" "}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateRows: "1fr 1fr",
                    }}
                  >
                    {" "}
                    <Box className="hrbodytext endText"> End date</Box>
                    <Box className="hrpageheding endDate">
                      {" "}
                      {userData?.endDate}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {subExpired && (
                <Box
                  sx={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "20px",
                    backgroundColor: "white",
                  }}
                >
                  *Subscription Expired*
                </Box>
              )}

              <Box className="deskTopPlans">
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "4fr 2fr 2fr 2fr",
                    gridGap: "10px",
                  }}
                >
                  <Box
                    className="tablerhrBody"
                    sx={{
                      height: "40px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    Services
                  </Box>
                  <Box
                    className="hrtablehedingborder tablerhrBody"
                    sx={{
                      background: "#BCBCBC",
                    }}
                  >
                    {" "}
                    Starter
                  </Box>
                  <Box
                    className="hrtablehedingborder tablerhrBody"
                    sx={{
                      background: "#BCBCBC",
                    }}
                  >
                    {" "}
                    Pro
                  </Box>
                  <Box
                    className="hrtablehedingborder tablerhrBody"
                    sx={{
                      background: "#E1F566",
                    }}
                  >
                    {" "}
                    Enterprise
                  </Box>
                </Box>

                {plans.map((el, index) => {
                  if (index == plans.length - 1) {
                    return (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "4fr 2fr 2fr 2fr",
                          gridGap: "10px",
                        }}
                      >
                        <Box
                          className="hrbodytext"
                          sx={{
                            minHeight: "40px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          {el?.name}
                        </Box>
                        <Box
                          className="hrtablehedingborderbodyfooter tablerhrBody"
                          sx={{
                            background: "#BCBCBC",
                          }}
                        >
                          {" "}
                          {el?.s ? <HrIcorTick color="#787978" /> : "-"}
                        </Box>
                        <Box
                          className="hrtablehedingborderbodyfooter tablerhrBody"
                          sx={{
                            background: "#BCBCBC",
                          }}
                        >
                          {el?.p ? <HrIcorTick color="#787978" /> : "-"}
                        </Box>
                        <Box
                          className="hrtablehedingborderbodyfooter tablerhrBody"
                          sx={{
                            background: "#E1F566",
                          }}
                        >
                          {" "}
                          {el?.e ? <HrIcorTick color="black" /> : "-"}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "4fr 2fr 2fr 2fr",
                        gridGap: "10px",
                      }}
                    >
                      <Box
                        className="hrbodytext"
                        sx={{
                          minHeight: "40px",
                          padding: "10px",
                        }}
                      >
                        {" "}
                        {el.name}
                      </Box>
                      <Box
                        className="hrtablehedingborderbody tablerhrBody"
                        sx={{
                          background: "#BCBCBC",
                        }}
                      >
                        {" "}
                        {el?.s ? <HrIcorTick color="#787978" /> : "-"}
                      </Box>
                      <Box
                        className="hrtablehedingborderbody tablerhrBody"
                        sx={{
                          background: "#BCBCBC",
                        }}
                      >
                        {" "}
                        {el?.p ? <HrIcorTick color="#787978" /> : "-"}
                      </Box>
                      <Box
                        className="hrtablehedingborderbody tablerhrBody"
                        sx={{
                          background: "#E1F566",
                        }}
                      >
                        {" "}
                        {el?.e ? <HrIcorTick color="black" /> : "-"}
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box className="HrProfilePlans">
                <Box className="HrProfilePlan">
                  <Box className="HrProfilePlanHeading">
                    <h2>Starter</h2>
                  </Box>
                  <Box className="HrProfilePlanItemContainer">
                    {plans
                      .filter((el) => el?.s)
                      .map((el, index) => {
                        return (
                          <Box className="HrProfilePlanItem">
                            {el?.s ? (
                              <HrIcorTick color="#787978" />
                            ) : (
                              <span>-</span>
                            )}
                            <span className="HrProfilePlanItemName">
                              {el.name}
                            </span>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                <Box className="HrProfilePlan">
                  <Box className="HrProfilePlanHeading">
                    <h2>Pro</h2>
                  </Box>
                  <Box className="HrProfilePlanItemContainer">
                    {plans
                      .filter((el) => el?.p)
                      .map((el, index) => {
                        return (
                          <Box className="HrProfilePlanItem">
                            {el?.p ? (
                              <HrIcorTick color="#787978" />
                            ) : (
                              <span>-</span>
                            )}
                            <span className="HrProfilePlanItemName">
                              {el.name}
                            </span>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                <Box className="HrProfilePlan current">
                  <Box className="HrProfilePlanHeading">
                    <h2>Enterprise</h2>
                  </Box>
                  <Box className="HrProfilePlanItemContainer">
                    {plans
                      .filter((el) => el?.e)
                      .map((el, index) => {
                        return (
                          <Box className="HrProfilePlanItem">
                            {el?.e ? (
                              <HrIcorTick color="#787978" />
                            ) : (
                              <span>-</span>
                            )}
                            <span className="current HrProfilePlanItemName">
                              {el.name}
                            </span>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default HrProfilePage;
