import EastIcon from "@mui/icons-material/East";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import FacebookIcon from "./FacebookIcon.svg";
import InstagramIcon from "./InstagramIcon.svg";
import LinkedinIcon from "./LinkedinIcon.svg";
import LoginFooterLogo from "./login-footer-logo.png";
import loginImg1 from "./login-img-01.png";
import loginImg2 from "./login-img-02.png";
import loginLogo from "./login-logo.png";
import "./login.css";
import quote from "./quote.png";

import { IP } from "../../../baseUrlAndConfig";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { displayOnDesktop } from "../../../utilities/commonStyles/commonStyles";

import Marquee from "react-easy-marquee";
import { useDispatch } from "react-redux";
import { supportLogger } from "../../../supportLoggerFunction";
import CommonModal from "../../../utilities/CommonModal/CommonModal";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import img1 from "./drive/1.png";
import img10 from "./drive/10.png";
import img11 from "./drive/11.png";
import img12 from "./drive/12.png";
import img13 from "./drive/13.png";
import img14 from "./drive/14.png";
import img15 from "./drive/15.png";
import img16 from "./drive/16.png";
import img18 from "./drive/18.png";
import img19 from "./drive/19.png";
import img2 from "./drive/2.png";
import img20 from "./drive/20.png";
import img21 from "./drive/21.png";
import img22 from "./drive/22.png";
import img23 from "./drive/23.png";
import img24 from "./drive/24.png";
import img25 from "./drive/25.png";
import img27 from "./drive/27.png";
import img28 from "./drive/28.png";
import img29 from "./drive/29.png";
import img3 from "./drive/3.png";
import img30 from "./drive/30.png";
import img4 from "./drive/4.png";
import img5 from "./drive/5.png";
import img6 from "./drive/6.png";
import img7 from "./drive/7.png";
import img8 from "./drive/8.png";
import img9 from "./drive/9.png";
const { createTheme } = require("@mui/material");
const steps = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
  {
    img: img8,
  },
  {
    img: img9,
  },

  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },

  {
    img: img13,
  },
  {
    img: img14,
  },
  {
    img: img15,
  },
  {
    img: img16,
  },
  // {
  //   img: img17,
  // },
  {
    img: img18,
  },
  {
    img: img19,
  },
  {
    img: img20,
  },
  {
    img: img21,
  },
  {
    img: img22,
  },

  {
    img: img23,
  },
  {
    img: img24,
  },
  {
    img: img25,
  },

  {
    img: img27,
  },
  {
    img: img28,
  },
  {
    img: img29,
  },
  {
    img: img30,
  },
];

function LoginPage({ messagingToken }) {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const dispatch = useDispatch();
  const goToUserDashboard = () => navigate("/hr/Learning");
  //const goToAdminDashboard = () => navigate("/admin");
  const goToAdminDashboard = () => navigate("/admin");
  const goToHrDashboard = () => navigate("/hr/Learning");
  const goToInstructorDashboard = () => navigate("/admin");
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const updateUserProfileInvitation = httpsCallable(
    functions,
    "updateUserProfileInvitation"
  );

  // const [role, setRole] = useState("");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [userSetNotification, setUserSetNotification] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [keep, setKeep] = useState(false);
  const [tooManyRequestsModal, setTooManyRequestsModal] = useState(false);
  ///========visibility password=======//
  const [showPassword, setShowPassword] = useState(false);

  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
    setNotification("");
  };

  const clearErrors = () => {
    setNotification("");
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    var config = {
      method: "get",

      url: `${IP}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: "data",
    };

    axios(config)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleLogin = async () => {
    clearErrors();

    if (userName == "") {
      return setUserSetNotification("Please enter your username");
    } else if (!userName.includes("@")) {
      return setUserSetNotification("Invalid Email");
    } else {
      setUserSetNotification("");
    }
    if (password == "") {
      return setNotification("Please enter your password");
    } else {
      setNotification("");
    }
    setLoading(true);

    var data = JSON.stringify({
      email: userName.trim(),
      password: password,
    });

    // deleteCookie();
    localStorage.removeItem("bearer");
    setCookie("bearer", "");
    // deleteCookiesInServer();
    // localStorage.setItem("clrs", JSON.stringify(newGreen));
    var config = {
      method: "post",

      url: `${IP}/auth/signIn`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        let userCredential = response.data;
        // console.log(userCredential);
        let temp = {
          email: userName,
          ui: userCredential?.uiPermission,
        };
        supportLogger(temp);

        setRolesAndPermission(userCredential.uiPermission);
        setCookie("bearer", userCredential.idToken);
        setCookie("rfToken", JSON.stringify(userCredential.refreshToken));
        setCookie("exp", Date.now() / 1000 + 1800);
        // saveToken({ token: messagingToken });

        let data = JSON.stringify({});

        var config = {
          method: "get",

          url: `${IP}/userProfile`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userCredential.idToken}`,
          },
          data: data,
        };
        axios(config)
          .then((res) => {
            let resObj = res.data.message;
            setCookie("ROLE", resObj.role);

            setCookie("EMNAME", resObj.name);
            setCookie("keepMeLogin", keep);
            let role = resObj.role;
            localStorage.setItem("idc", resObj.companyId);
            setLoading(false);
            if (role == "ADMIN") goToAdminDashboard();
            else if (role == "HR") goToHrDashboard();
            else if (role == "USER") goToUserDashboard();
            // else {
            //   goToHrDashboard();
            // }
          })
          .catch((er) => {
            // console.log(er);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        supportLogger(err?.response);
        supportLogger(err);
        // console.log(first)
        console.log(err.response);
        if (err.response.data.error === "USER_DISABLED") {
          return setNotification("This user archived Please contact to serien");
        }
        if (
          err.response.data.error == "INVALID_PASSWORD"
          // err.response.data.error == "EMAIL_NOT_FOUND"
        ) {
          return setNotification("Enter valid Credentials");
        }

        if (err.response.data.error == "EMAIL_NOT_FOUND") {
          return setNotification("This user does not exist");
        }
        if (err.response.status == 429) {
          setTooManyRequestsModal(true);
        }

        return setNotification("Something went wrong");
      });
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        goToHome();
      })
      .catch((error) => {
        console.log(error.code);
        // An error happened.
      });
    //   fire.auth().signOut();
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // //console.log(user.accessToken);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  const setKipemeLog = (e) => {
    setKeep(!keep);
    ////console.log(keep)
    //  //console.log(e)
  };
  useEffect(() => {
    authListener();
  }, []);
  //console.log(keep)
  //console.log(keep)
  const deletecookies = () => {
    localStorage.removeItem("bearer");
    setCookie("bearer", "");
  };
  return (
    <Box
      // data-aos="zoom-out"
      // data-aos-duration="2000"
      sx={{ pt: { xs: "50px", md: "50px" }, backgroundColor: "#F3F1FF" }}
    >
      <Container
        sx={{
          //...responsiveReverseFlexDirection,
          // height: { xs: "auto", md: "100vh" },
          gap: "40px",
          display: "flex",
        }}
      >
        <Box
          className={classes.loginMain}
          // data-aos="fade-right"
          // data-aos-duration="3000"
          sx={{
            width: { xs: "100%", md: "40%" },
          }}
        >
          <Box
            sx={{
              zIndex: "1",
            }}
          >
            <Box>
              <img src={loginLogo} />
            </Box>
            <Stack
              component="form"
              spacing={3.5}
              style={{ marginTop: "100px" }}
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  width: "100%",
                  borderRadius: "15px",
                  padding: "15px",
                  outline: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  required
                  autofocus={userName.length == 0}
                  value={userName}
                  onChange={(e) => {
                    deletecookies();
                    setUserName(e.target.value);
                  }}
                  placeholder="Official email address"
                  style={{
                    width: "100%",
                    padding: "7px",
                    border: "none",
                    outline: "none",
                    background: "transparent",
                  }}
                />
              </Box>
              <Box>
                {userSetNotification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {userSetNotification}
                  </span>
                )}
              </Box>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "15px",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={password}
                    id={showPassword ? "showPassword" : "hiddePassword"}
                    onChange={(e) => {
                      deletecookies();
                      setPassword(e.target.value);
                    }}
                    style={{
                      border: "none",
                      outline: "none",
                      width: "90%",
                      background: "transparent",
                    }}
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      zIndex: "1",
                    }}
                  >
                    {showPassword ? (
                      <Tooltip title="Hide Password" placement="top-start">
                        <VisibilityIcon sx={{ color: "#6846C7" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" placement="top-start">
                        <VisibilityOffIcon sx={{ color: "#6846C7" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Box>
              <Box>
                {notification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {notification}
                  </span>
                )}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: "#000000" }}
                      onChange={(e) => {
                        setKipemeLog(e.target.value);
                      }}
                    />
                  }
                />
                <label
                  style={{
                    fontWeight: 500,
                    color: "#000000",
                    fontSize: { xs: "12px", md: "14px" },
                    marginLeft: "-20px",
                    fontFamily: "'Readex Pro', sans-serif",
                  }}
                >
                  Keep me logged in
                </label>
              </Box>
              <Box>
                <Button
                  className={classes.loginBtn}
                  // onClick={handleLogin}
                  type="submit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Manrope",
                    textTransform: "none",
                    width: "100%",
                    py: 2,
                    borderRadius: "15px",
                    fontSize: "16px",
                    fontWeight: 600,
                    background: "#6846C7",
                    color: "#fff",
                    "&:hover": {
                      background: "#6846C7",
                    },
                  }}
                >
                  <Box>{loading ? "logging in ..." : "Log in"}</Box>
                  <Box className={classes.loginBtnIcon}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <EastIcon />
                    </Box>
                  </Box>
                </Button>
              </Box>

              <Box>
                <Link to="/forgot-password-page">
                  <Typography
                    sx={{
                      color: "#6846C7",
                      fontWeight: 600,
                      fontSize: { xs: "12px", md: "14px" },
                    }}
                  >
                    Forgot password?
                  </Typography>
                </Link>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box
          className={classes.loginImgMain}
          sx={{
            ...displayOnDesktop,
            width: "60%",
            zIndex: "4",
          }}
        >
          <img
            // data-aos="fade-down-right"
            // data-aos-duration="3000"
            className={classes.loginImg1}
            src={loginImg1}
            alt=""
          />
          <img
            // data-aos="fade-up-left"
            // data-aos-duration="3000"
            className={classes.loginImg2}
            src={loginImg2}
            alt=""
          />
          {/* <img
            // data-aos="zoom-out"
            // data-aos-duration="3000"
            // src={loginImg3}
            alt=""
          /> */}
          <Box
            className={classes.loginImg3}
            sx={{
              height: "200px",
              width: "500px",
              borderRadius: "30px",
              backgroundColor: "#E1F566",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px",
            }}
          ></Box>
          <Typography
            className={classes.animeText}
            sx={{
              height: "200px",
              width: "500px",
              fontSize: "44px",
              fontWeight: 700,
              fontFamily: "Manrope",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            <img
              src={quote}
              style={{ position: "relative", top: "-1.7rem", left: "0.6rem" }}
            />{" "}
            Diversity, equity and inclusion
          </Typography>
        </Box>
        {/* <Box
          sx={{
            //  ...displayOnMobile,
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "100%", borderRadius: "10px" }}
            src={responsiveLoginImg}
            alt=""
          />
        </Box> */}
      </Container>

      <Box>
        <Box
          sx={{ mt: "50px", backgroundColor: "#fff", py: "24px", zIndex: "1" }}
        >
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <Marquee duration={50000} reverse={true}>
                {steps.map((image) => (
                  <Box sx={{ margin: "20px" }}>
                    <img
                      src={image.img}
                      style={{
                        height: "70px",
                        objectFit: "contain",
                        aspectRatio: "3/2",
                      }}
                    />
                  </Box>
                ))}
              </Marquee>
            </Box>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: "#6846C7", py: "50px" }}>
          <Container>
            <Box
              className="footerResponsive"
              sx={{ display: "flex", gap: "20px" }}
            >
              <Box sx={{ width: "70%" }}>
                <Box>
                  <img src={LoginFooterLogo} />
                </Box>
                <Box
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontFamily: "Manrope",
                    mt: "25px",
                  }}
                >
                  © Serein Inc. Copyright 2023
                </Box>
                <Box
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontFamily: "Manrope",
                    mt: "6px",
                  }}
                >
                  Data Security and Privacy Policy
                </Box>
              </Box>
              <Box
                sx={{ width: "5%", display: "flex", justifyContent: "center" }}
              >
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: "#fff",
                    width: "1.4px",
                    opacity: "0.1",
                  }}
                />
              </Box>
              <Stack
                spacing={3}
                sx={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "30px" }}
                >
                  <a
                    href="https://www.linkedin.com/company/serein/mycompany/"
                    target="_blank"
                  >
                    <img src={LinkedinIcon} />
                  </a>
                  {/* <a
                    href="https://www.linkedin.com/company/serein/mycompany/"
                    target="_blank"
                  >
                    <img src={TwitterIcon} />
                  </a> */}
                  <a href="https://www.facebook.com/sereininc" target="_blank">
                    <img src={FacebookIcon} />
                  </a>
                  <a
                    href="https://www.instagram.com/sereininc/"
                    target="_blank"
                  >
                    <img src={InstagramIcon} />
                  </a>
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
      <CommonModal
        open={tooManyRequestsModal}
        close={() => {
          setTooManyRequestsModal(false);
        }}
      />
    </Box>
  );
}

export default LoginPage;

/* <div className="loginpage">
  <div className="loginpage-main">
    <div className="loginpage-main-left">
      <div className="loginpage-main-left-cnt">
        <div className="loginpage-main-left-cnt-items">
          <div className="loginpage-main-left-cnt-items-text-1">Log in</div>
          <div className="loginpage-main-left-cnt-items-text-2">
            Log in with your credentials provided by admin
          </div>
          <div className="loginpage-main-left-cnt-items-text-3">
            Enter Your Username
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="text" placeholder="name@domain.com" required
              value={userName}
              onChange={(e) => setUserName(e.target.value)} />
            <p className="errorMsg">{userNameError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-4">
            Enter Your Password
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="password" placeholder="At least 8 charactor" required
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <p className="errorMsg"> {passwordError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-5">
            <FormControlLabel
              control={<Checkbox color="success" />}
              label="Keep me logged in"
            />
          </div>
          <div className="loginpage-main-left-cnt-items-login-btn">
            <button onClick={handleLogin}>Log in</button>
          </div>
          <div className="loginpage-main-left-cnt-items-text-6">
            Forgot Password?
          </div>
        </div>
      </div>
    </div>
    <div className="loginpage-main-right">
      <div className="loginpage-main-right-cnt">
        <img src={loginImg} alt="loading" />
      </div>
    </div>
  </div>
</div> */
